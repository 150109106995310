<template>
    <div class="product-wrapper">	
	    <div style="position: absolute; margin-top:8px;color:#409eff;border: 0 solid #000;
    box-sizing: border-box;">
		  <div v-for="(item,index) in product.prolist2">{{item.detail}}</div>
		</div>
		 <div class="pictrue" @click="detail(product.goods_id)">
            <img :src="product.goods_image" alt="" align="center">
	
		
        </div>
		
        <div class="money flex-start">
                <template v-if="currency === 'USD'">
                    <span class="font-color">US${{product.us_price}}</span>
                </template>
                <template v-else>
                    <span class="font-color">{{product.danwei+product.final_price}}</span>
                </template>
            <!-- <span class="y_money">¥388.00</span> -->
        </div>
        <div class="name" v-html="product.goods_name"></div>

    </div>
</template>
<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import productNames from "./Product.json"; 

export default {
  props: ['product'],
  data() {
    return {
      locale: window.localStorage.getItem('locale') || this.$i18n.locale,
      currency: window.localStorage.getItem('currency'),
      uniacid: window.localStorage.getItem('uniacid'),
    };
  },
  created() {
    if (this.locale === 'zh-CN') {
      this.product.goods_name = chineseLanguageLoader(this.product.goods_name, { language: 'zh-CN' });
    } else if (this.locale === 'EN' && this.uniacid && productNames[this.uniacid]) {
      this.product.goods_name = productNames[this.uniacid][this.product.goods_id] || "Unknown Product";
    }
  },
  methods: {
    detail(id) {
      this.$router.push({ path: '/product', query: { id } });
    }
  }
};
</script>
<style lang="scss" scoped>
    .product-wrapper{
        @media (min-width:320px)  { width: 100%; }
        @media (min-width:481px)  { width: 40%; }
        @media (min-width:641px)  { width: 40%; }
        @media (min-width:961px)  { width: 40%; }
        @media (min-width:1025px) { width: 210px; }
        @media (min-width:1281px) { width: 210px; }
        
        height: 320px;
        background-color: #fff;
        padding: 16px;
        margin: 20px 20px 0 0;
        border-radius: 2px;
        cursor: pointer;
        box-sizing: border-box;
        transition: all .6s;
        position: relative;
        &:hover{
            -webkit-box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
            box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
            -webkit-transform: translate3d(0,-2px,0);
            transform: translate3d(0,-2px,0);
        }
        .pictrue{
            width: 192px;
            height: 192px;
            img{
                width: 92%;
                height: 100%;
            }
        }
        .money{
            margin-top: 12px;
            .font-color{
                font-size: 22px;
                font-weight: 700;
                color: #e93323!important;
            }
            .y_money{
                color: #aaa;
                font-size: 12px;
                text-decoration: line-through;
                margin-left: 8px;
                line-height: 22px;
                height: 12px;
            }
        }
        .name{
            margin-top: 8px;
            color: #5a5a5a;
            height: 37px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
        }
    }
</style>