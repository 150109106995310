// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import global from './global'; // Import the global module

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        global
    }
});
