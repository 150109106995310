<template>
    <div :style="'background:'" class="search-wrapper">
        <div class="w1200 flex-bt content">
            <div class="left flex-start">
                <a href="/" class="logo">
                    <!-- <img :src="require('@/assets/images/logo.png')" alt=""> -->
                    <img :src="logo" alt="">
                </a>
                <ul>
                    <li>
                        
                    </li>
                </ul>
            </div>
            <div class="right flex-end">
                <div class="search flex-bt">
                    <div class="text acea-row row-middle"><span class="iconfont icon-xiazai5"></span><input type="text" :placeholder="$t('Search')" v-model="keyword"></div>
                    <div class="bnt bg-color" @click="searchProduct">{{$t('Search')}}</div>
                </div>
            </div>
        </div>
        <!-- menu -->
		<menu-box :menu="menu" :webset="webset" />
    </div>
</template>
<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import MenuBox from '@/components/Menu'
export default {
	props:['logo','leimulist','webset'],
    components: {MenuBox},
    data() {
        return {
			uniacid:parseInt(window.localStorage.getItem('uniacid')),

            keyword: '',
            menu: [
                {
                    name: '首頁',
                    path: '/home',
                },
                {
                    name: 'loading',
                    children: [
                        {name: '类目1', path: '/category'},
                        {name: '类目2', path: '/category'},
                        {name: '类目3', path: '/category'},
                        {name: '类目4', path: '/category'},
                    ],
                }
                
            ]
        }
    },
	   created(){
		  this.gettopmenu()
		   
		   },
    methods: {
		//获取分类
	 async gettopmenu(){
		 const ll= this.$i18n.locale 
		 console.log('ll',ll);
		 this.uniacid =window.localStorage.getItem('uniacid');
         //let uniacid =19;
	 	let res = await this.$http.get('/user/topmenu',{uniacid:this.uniacid,locale:ll})
	 	if(res){
	 		 this.menu= res.data 
			 console.log(this.menu);


            if(ll === 'zh-CN') {
                for (let i = 0; i < res.data.length; i++) {
                    const str = chineseLanguageLoader(res.data[i].name, { language: 'zh-CN' });
                    this.menu[i].name = str;
                }
            }
            else if(ll === 'EN' && this.uniacid == 17) {
                console.log('ENNNNNNNNNNNNNNNNNN')
                this.menu[1].name = "Professional Toothbrush Series";
                this.menu[2].name = "Long Handle Interdental Brush";
            }
	 	 
	 	}
		
	 },
		
        searchProduct() {
            this.keyword && this.$router.push({name: 'ProductList', params:{keyword: this.keyword, uniacid: this.uniacid}})
        }
    },
	  watch: {
		    'locale':function(locate){
				  console.log('change locale....');
				}
		  }
}
</script>
<style lang="scss" scoped>
    .search-wrapper{
		margin-top: 30px;
        width: 100%;
        background: #fff;
        .w1200{
            height: 100%;
        }
        .content{
            height: 96px;
        }
        .left{
            width: 100%;
            height: 100%;
            .logo{
                height: 60%;
                img{
                    height: 100%;
					    border-radius: 10%;
                    // width: 200px;
                }
            }
        }
        .right{
            height: 100%;
            .search{
                max-width: 320px;
                height: 40px;
                border: 1px solid #E93323;
                border-radius: 2px;
                cursor: pointer;
                .text{
                    width: 200px;
                    padding-left: 14px;
                    color: #C1C1C1;
                    input{
                        width: 150px;
                        height: 36px;
                        border:none;
                        outline: none;
                        padding-left: 10px;
                        margin-top: -2px;
                    }
                    .iconfont{
                        font-size: 15px;
                        margin-right: 5px;
                    }
                }
                .bnt{
                    width: 64px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    color: #fff;
                    background: #e93323!important;
                }
            }
        }
    }
</style>