<template>
    <div :style="'background: '+webset['homebakcolor']" class="home-wrapper">
        <search :logo="webset.logo" :webset="webset" leimulist="topmenu" />
        <!-- banner -->
        <div class="banner w1200">
            <el-carousel trigger="click" height="400px">
                <el-carousel-item v-for="(item, index) in banner" :key="index">
                    <img :src="item.image" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- product -->
        <div class="product-list">
            <div class="main w1200">
                <div class="title-box flex-start">
                    <span class="title">{{ currentBrand }} Products</span>
                </div>
                <div class="content flex-bt">
                    <product v-for="(item, index) in filteredGoods" :key="index" :product="item" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from '@/components/Search';
import Product from '@/components/Product';

// Function to create a filter strategy
function brandfilter(brandMap) {
    return {
        filterFunction: (item, brandName) => item.brand?.trim() === brandName,
        brandMap
    };
}

const filterStrategies = {
    20: brandfilter({
        'brand-lp': 'Leon Paul',
        'brand-uf': 'Unique fencing',
        'brand-allstar': 'Allstar',
        'brand-uhlmann': 'Uhlmann'
    }),
    43: brandfilter({
        'brand-abc': 'abc',
        'brand-def': 'def',
        'brand-ghi': 'ghi',
        'brand-jkl': 'jkl'
    })
};

export default {
    name: 'BrandFilter',
    components: { Search, Product },
    data() {
        return {
            html: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
            topmenu: [],
            uniacid: window.localStorage.getItem('uniacid'),
            moduleList: [],
            leimugoodslist: [],
            banner: [],
            webset: [],
            menu: {
                active: '',
                list: []
            },
            leimulist: [],
            size: 10,
            page: 1,
            pageCount: 0,
            goodsList: [],
            leimu: [],
            nowleimu: '',
            filteredGoods: [],
            filterStrategies,
        };
    },
    computed: {
        currentRoute() {
            return this.$route.path.toLowerCase().split('/').pop();
        },
        currentStrategy() {
            const uniacid = parseInt(window.localStorage.getItem('uniacid')) || 20;
            return this.filterStrategies[uniacid] || this.filterStrategies[20];
        },
        currentBrand() {
            return this.currentStrategy.brandMap[this.currentRoute] || '';
        }
    },
    watch: {
        // Re-filter when route changes
        '$route'() {
            this.filterGoodsByBrand();
        }
    },
    created() {
        // Set uniacid based on route meta
        if (this.$route.meta.uniacid) {
            this.uniacid = this.$route.meta.uniacid;
            window.localStorage.setItem('uniacid', this.uniacid);
        }
        this.getuniacid();
    },
    methods: {
        async getuniacid() {
            // If we have a route-specific uniacid, skip the normal uniacid detection
            if (this.$route.meta.uniacid) {
                this.webset = JSON.parse(window.localStorage.getItem('webinfo') || '{}');
                document.title = this.webset.webtitle || '';
                this.getleimugoods();
                return;
            }

            var ss = window.localStorage.getItem('webinfo');
            if (!ss) {
                var url = window.location.href;
                var dz_url = url.split("#")[0];
                var www_dz_url = dz_url.split("/")[2];
                var centerdomain = www_dz_url.split(".");
                console.log('centerdomain:', centerdomain);
                let res;
                if (centerdomain[1] == 'nowaittech') {
                    res = await this.$http.get('app.layout/weblist', { centerdomain: centerdomain[0] });
                    console.log('websiteinfo:', res);
                } else {
                    res = await this.$http.get('app.layout/weblist', { siteroot: www_dz_url });
                    console.log('websiteinfo:', res);
                }
                console.log('websiteinfo:', res);
                ss = JSON.stringify(res[0]);
            }
            var ssarray = JSON.parse(ss);
            console.log('ss', ss);
            console.log('ssarray.ucid', ssarray['ucid']);
            this.webset = ssarray;
            
            // Only set uniacid to 20 if not already set by route
            if (!this.$route.meta.uniacid) {
                this.uniacid = 20;
                window.localStorage.setItem('uniacid', this.uniacid);
            }
            
            document.title = ssarray.webtitle;
            this.getleimugoods();
        },
        async getleimugoods() {
            let res = await this.$http.get('/app.layout/getmulugoods', {
                uniacid: this.uniacid
            });
            if (res) {
                console.log('gsl', res);
                this.leimugoodslist = res;
                window.localStorage.setItem('leimugoodslist', JSON.stringify(res));
                this.filterGoodsByBrand();
                this.getHomeLayout();
                this.getleimu1();
            }
        },
        async getHomeLayout() {
            let res = await this.$http.get('/app.layout', {
                url: '/pages/tabbar/index/index', uniacid: this.uniacid
            });
            if (res.code !== 0) return;
            this.moduleList = res.data.list;
            this.banner = res.data.list.reduce((arr, item) => {
                item.type == 'carousel' && (arr = item.data);
                return arr;
            }, []);
            console.log('banner', this.banner);
        },
        async getleimu1() {
            console.log('mulu1');
            let res = await this.$http.get('/app.layout/mulu1', {
                uniacid: this.uniacid
            });
            if (res) {
                console.log(res);
                this.leimulist = res;
                console.log('ok');
                console.log(this.leimulist);
                let locale = window.localStorage.getItem('locale') || this.$i18n.locale;
                if (locale === 'zh-CN') {
                    for (let i = 0; i < res.length; i++) {
                        const str = chineseLanguageLoader(res[i].title, { language: 'zh-CN' });
                        this.leimulist[i].title = str;
                    }
                }
            }
        },

        filterGoodsByBrand() {
            const brandName = this.currentBrand;
            if (!brandName) return;
            
            this.filteredGoods = Object.values(this.leimugoodslist)
                .flatMap(category => category.goods || [])
                .filter(item => this.currentStrategy.filterFunction(item, brandName));

            console.log(
                'Filtered goods for uniacid:',
                this.uniacid,
                'brand:',
                brandName,
                'count:',
                this.filteredGoods.length
            );
        },

        // Helper method to add new filter strategy
        addFilterStrategy(uniacid, strategy) {
            this.filterStrategies[uniacid] = {
                filterFunction: strategy.filterFunction,
                brandMap: strategy.brandMap
            };
        }
    }
};
</script>


<style lang="scss" scoped>
	.home-wrapper{
		.banner{
			height: 400px;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.product-list{
			margin: 10px auto;
			margin-top: 50px;
			.main{
				.title{
					font-size: 20px;
					font-weight: bold;
				}
				.content{
					box-sizing: border-box;
					padding: 30px 0;
					flex-wrap: wrap;
					//overflow: scroll;
					overflow-y: auto;
					/*max-height: 680px;*/
					// background: #fff;
				}
				.menu{
					margin-left: 20px;
					.items{
						margin-right: 10px;
						font-weight: 400;
						
						cursor: pointer;
						&.active{
							color: red;
							font-weight: bold;
							border-bottom: 2px solid #e93323;
						}
					}
				}
			}
		}
	}
</style>
