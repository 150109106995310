<template>
    <div :style="'background: '" class="menu-wrapper">
        <div class="w1200">
            <el-menu
                :default-active="active"
                class="el-menu-demo"
                mode="horizontal"
                router
                @select="handleSelect"
            >
            <template v-for="(parent, index) in menu">
                    <el-menu-item v-if="!parent.children || parent.children.length == 0" :index="parent.path" :route="parent.path" :key="index">{{ parent.name }}</el-menu-item>
                    <el-submenu :index="String(index)" :key="index" v-else>
                        <template slot="title">{{ parent.name }}</template>
                        <el-menu-item 
                            v-for="(child, childIndex) in parent.children" 
                            :route="child.path" 
                            :key="childIndex" 
                            :index="String(index + '-' + childIndex)">
                            {{ getHardCodedName(child.name, childIndex) }}
                        </el-menu-item>
                    </el-submenu>
                </template>
                <!-- Unique fencing brand filtering -->
                <el-submenu :index="'new-menu'" :key="'new-menu'" v-if="uniacid==20">

                    <template slot="title">{{$t('Brand')}}</template>
                    <el-menu-item :index="'brand-uf'" :route="'/brand-uf'" :key="'brand-uf'">Unique fencing</el-menu-item>
    <el-menu-item :index="'brand-allstar'" :route="'/brand-Allstar'" :key="'brand-allstar'">Allstar</el-menu-item>
    <el-menu-item :index="'brand-lp'" :route="'/brand-LP'" :key="'brand-lp'">Leon Paul</el-menu-item>
    <el-menu-item :index="'brand-uhlmann'" :route="'/brand-Uhlmann'" :key="'brand-uhlmann'">Uhlmann</el-menu-item>
                </el-submenu>
                <!-- K520C brand filtering -->

                <el-menu-item
                    v-if="uniacid==43 "
                    :index="'/service-provider'"
                    :route="'/service-provider'"
              
                    >
                    {{$t('Service Provider')}}
                    </el-menu-item>

      
                
                <!-- Moved this inside el-menu -->
                    <el-menu-item
                    v-if="uniacid==20 && locale === 'EN'"
                    :index="'aboutus'"
                    :route="'/ufaboutus'"
              
                    >
                    {{$t('About us')}}
                    </el-menu-item>

                    <el-menu-item
                    v-if="uniacid==20 && locale === 'zh-HK'"
                    :index="'aboutus'"
                    :route="'/ufaboutus'"
         
                    >
                    {{$t('About us')}}
                </el-menu-item>

                <el-menu-item
                    :index="'/aboutus-k520c'"
                    :route="'/aboutus-k520c'"
            
                     v-if="uniacid==43"
                >     {{$t('About us')}}</el-menu-item>
            </el-menu>
        </div>
    </div>
</template>
<script>
export default {
    props: ['menu', 'webset'],
    data() {
        return {
            uniacid: parseInt(window.localStorage.getItem('uniacid')),
            active: '',
            locale: this.$i18n.locale,
            hardCodedNames: [
                "Course", "Gloves", "Sword Uniform", "Mask",
                "Sword Blade / Sword Accessories", "Sword Pants", "Sword Bag", "London"
            ],
            hardCodedNames2: [
                "London"
            ]
        };
    },
    created() {
        console.log('uniacid:', this.uniacid, 'locale:', this.locale);
        console.log('Menu:', this.menu);
    },
    methods: {
        handleSelect(e) {
            console.log('Selected:', e);
        },
        getHardCodedName(name, index) {
            if (this.uniacid === 20 && this.locale === 'EN' && index >= 0 && index < this.hardCodedNames.length) {
                return this.hardCodedNames[index];
            } 
            return name;
        }
    }
};
</script>
<style scoped>
    .menu-wrapper{
        width: 100%;
        background: #fff;
        margin-top: 15px;
    }
</style>