import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' // Make sure this is correctly pointing to your Vuex store
import ElementUI from 'element-ui'
import http from './utils/axios'
import i18n from './i18n'
import 'element-ui/lib/theme-chalk/index.css';
import FBSignInButton from 'vue-facebook-signin-button'
import chineseLanguageLoader from 'chinese-language-loader/lib/main.js';

Vue.use(FBSignInButton)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$http = http // Ensure axios is set up correctly

Vue.directive('translate-chinese', {
  bind(el, binding) {
    const { value, modifiers } = binding;
    const { language } = modifiers;

    const originalText = el.textContent;
    let translatedText = '';

    for (const char of originalText) {
      if (/[\u4e00-\u9fff]/.test(char)) {
        const translation = chineseLanguageLoader(char, 'zh-CN');
        translatedText += translation;
      } else {
        translatedText += char;
      }
    }

    el.textContent = translatedText;
  }
});

// Initialize the main Vue instance
new Vue({
  router,
  store, // Ensures Vuex store is used
  i18n,
  render: h => h(App)
}).$mount('#app')
